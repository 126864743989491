<script>
import {
	mdiCalendarOutline,
	mdiDeleteOutline,
	mdiPlus,
	mdiCloseCircleOutline, mdiCheckCircle, mdiClockOutline, mdiHistory
} from "@mdi/js"
import {
	getHolidays,
	getVacationApplications,
} from "@/services/api/requests"
import CardApplicationEmployee from "@/components/Card/CardApplicationEmployee.vue"
import CardNoVacations from "@/components/Card/CardNoVacations.vue"
import {mapGetters} from "vuex";
export default {
	name: "EmployeeView",
	components: {CardNoVacations, CardApplicationEmployee},
	data() {
		return {
			applications: [],
			loadingApplications: false,
			modalCreate: false,
			currentYear: 2024,
			submitting: false,
			showDatePicker: false,
			newVacationApplication: {
				vacations: [],
				year: null,
			},
			editedVacationApplication: null,
			newRange: null,
			editedRange: null,
			editedRangeId: null,
			holidays: [],
			displayedDateOnCreateDatePicker: null,
			icons: {
				clock: mdiClockOutline,
				plus: mdiPlus,
				calendar: mdiCalendarOutline,
				done: mdiCheckCircle,
				delete: mdiDeleteOutline,
				notDone: mdiCloseCircleOutline,
				history: mdiHistory,
			},
			editingApplication: false,
		}
	},
	computed: {
		...mapGetters("User",{
			daysAvailable: "getDaysAvailable",
		}),
		showNoCurrentVacation() {
			return this.applications.length === 0
		},
	},
	async created() {
		await this.fetchApplications(true)
	},
	methods: {
		async fetchApplications(holidays = false) {
			this.loadingApplications = true
			const { results } = await getVacationApplications()
			this.applications = results
			if (holidays) {
				const { holiday_list } = await getHolidays(this.currentYear)
				this.holidays = holiday_list
			}
			this.loadingApplications = false
		},
	},
}
</script>

<template>
	<v-container style="max-width: 800px">
		<v-row>
			<v-col cols="12">
				<h1>
					Мои заявки на отпуск в {{ currentYear }} году
				</h1>
				<v-chip
					color="primary"
					class="ma-2"
					outlined
				>
					Доступно дней: {{ daysAvailable }}
				</v-chip>
			</v-col>
			<v-col v-if="loadingApplications">
				<v-progress-linear
					indeterminate
					color="primary"
				/>
				<p class="text-center">
					Загрузка...
				</p>
			</v-col>
			<v-col
				v-else-if="showNoCurrentVacation"
				cols="12"
			>
				<CardNoVacations
					:current-year="currentYear"
					:days-available="daysAvailable"
					:holidays="holidays"
					:disabled="$store.state['App'].disableCreateAndEdit"
					@update="fetchApplications"
				/>
			</v-col>
			<v-col v-else>
				<CardApplicationEmployee
					v-for="(application, applicationIndex) in applications"
					:key="applicationIndex"
					:application="application"
					:index="applicationIndex"
					:holidays="holidays"
					:current-year="currentYear"
					:days-available="daysAvailable"
					@update="fetchApplications"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped lang="scss">
</style>